const languages = {
  GEO: {
    navbar: {
      sections: ['მთავარი', 'შესახებ', 'კონტაქტი'],
      nav1: 'მთავარი',
      nav2: 'შესახებ',
      nav3: 'კონტაქტი',
      name: 'იური წერეთელი',
    },
    consult: {
      title: 'ადვოკატის მომსახურება',
      description:
        'უფასო იურიდიული კონსულტაცია, იურიდიული მომსახურება სისხლის, სამოქალაქო და ადმინისტრაციულ საქმეებზე',
      button: 'კონსულტაციის დაჯავშნა',
    },
    info: {
      name: 'იური წერეთელი',
      title: 'სამუშაო გამოცდილება',
      firstParagraph:
        'საერთო სპეციალიზაციის ადვოკატი (სისხლის, სამოქალაქო და ადმინისტრაციულ საქმეებზე)',
      secondParagraph: '1990-2007 წლები - გენერალური პროკურატურა - პროკურორი',
      thirdParagraph: '2007 წლიდან სსიპ "საქართველოს ადვოკატთა ასოციაციის" წევრი',
    },
    contact: {
      call: 'დაგვიკავშირდით ნომერზე',
      or: 'ან',
      mail: 'მოგვწერეთ მეილზე',
      tell: 'გვიამბეთ თქვენი საჭიროებების შესახებ',
      nameTitle: 'თქვენი სახელი',
      mailTitle: 'ელ-ფოსტა',
      messageTitle: 'შეტყობინება დაწერეთ აქ',
      button: 'შეტყობინების გაგზავნა',
      messageRequested1: 'თქვენი შეტყობინება მიღებულია!',
      messageRequested2: 'მალე დაგიკავშირდებით!',
    },
    footer: {
      nav1: 'მთავარი',
      nav2: 'შესახებ',
      nav3: 'კონტაქტი',
      copyright: '2024 იური წერეთელი. ყველა უფლება დაცულია.',
    },
  },
  ENG: {
    navbar: {
      sections: ['Main', 'About', 'Contact'],
      nav1: 'Main',
      nav2: 'About',
      nav3: 'Contact',
      name: 'Iuri Tsereteli',
    },
    consult: {
      title: 'Your Personal Lawyer',
      description:
        'Offering free legal consultations and services in criminal, civil, and administrative cases',
      button: 'Book a consultation',
    },
    info: {
      name: 'Iuri Tsereteli',
      title: 'Professional Background',
      firstParagraph:
        'general specialization lawyer (criminal, civil and administrative cases)',
      secondParagraph: `1990-2007 - Prosecutor of the General Prosecutor's Office`,
      thirdParagraph: 'Member of the Georgian Bar Association since 2007',
    },
    contact: {
      call: 'Call Us',
      or: 'or',
      mail: 'Contact Us via Email',
      tell: 'Tell Us About Your Needs',
      nameTitle: 'Your Name',
      mailTitle: 'Email',
      messageTitle: 'Message',
      button: 'Send a Message',
      messageRequested1: "We've received your message!",
      messageRequested2: "We'll be in touch soon!",
    },
    footer: {
      nav1: 'Main',
      nav2: 'About',
      nav3: 'Contact',
      copyright: '2024 Iuri Tsereteli. All Rights Reserved.',
    },
  },
} as const;

export default languages;

import style from './Info.module.scss';
import personalImage from '../../image/iuri.jpg';
import { useContext, useEffect } from 'react';

// icons
import { MdOutlineMail } from 'react-icons/md';
import { FaPhone } from 'react-icons/fa6';
import ScrollId from '../ScrollId/ScrollId';
import { LanguageContext } from '../../context/language.context';
import languages from '../../data/languages';

const Info = () => {
  const { text } = useContext(LanguageContext);

  // animation logic
  useEffect(() => {
    const elements = document.querySelectorAll(
      `.${style.titleAnimation}, .${style.animation}`
    );
    const observer = new IntersectionObserver(
      (entries) => {
        console.log(entries); // Add this line for debugging
        entries.forEach((entry) => {
          if (entry.target.classList.contains(style.titleAnimation)) {
            // Apply styles or animations for text elements
            entry.target.classList.toggle(
              `${style.titleShow}`,
              entry.isIntersecting
            );
            if (entry.isIntersecting) observer.unobserve(entry.target);
          }
          if (entry.target.classList.contains(style.animation)) {
            // Apply styles or animations for text elements
            entry.target.classList.toggle(
              `${style.animationShow}`,
              entry.isIntersecting
            );
            if (entry.isIntersecting) observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 1,
      }
    );

    elements.forEach((element) => observer.observe(element));
  }, []);

  return (
    <div id="container-შესახებ" className={style.info}>
      <ScrollId id={languages.ENG.navbar.sections[1]} />
      <div className={style.inner}>
        <div className={style.personal}>
          <div
            className={`${style.image} ${style.animation}`}
            style={{ backgroundImage: `url(${personalImage})` }}
          ></div>
          <h3 className={`${style.name} ${style.animation}`}>{text.info.name}</h3>
          <div className={`${style.mail} ${style.animation}`}>
            <MdOutlineMail /> iuritsereteli12@gmail.com
          </div>
          <div className={`${style.number} ${style.animation}`}>
            <FaPhone />
            (+995) 599 47 80 16{' '}
          </div>
        </div>
        <div className={style.personalInfo}>
          <h1 className={style.titleAnimation}>{text.info.title}</h1>
          <ul>
            <li className={style.titleAnimation}>{text.info.firstParagraph}</li>
            <li className={style.titleAnimation}>{text.info.secondParagraph}</li>
            <li className={style.titleAnimation}>{text.info.thirdParagraph}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Info;

import Consult from '../Consult/Consult';
import Contact from '../Contact/Contact';
import Info from '../Info/Info';
import ScrollId from '../ScrollId/ScrollId';
import style from './Main.module.scss';
import Socials from '../Socials/Socials';
import languages from '../../data/languages';

const Main = () => {
  return (
    <main className={style.main}>
      <ScrollId id={languages.ENG.navbar.sections[0]} />
      <Consult />
      <Info />
      <Contact />
      <Socials />
    </main>
  );
};

export default Main;

import { useState, useEffect, useContext } from 'react';
import style from './Navbar.module.scss';
import Logo from '../Logo/Logo';
import { FaPhone } from 'react-icons/fa6';
import MiniLogo from '../Logo/MiniLogo';
import NavResponsive from './NavResponsive';
import { LanguageContext } from '../../context/language.context';
import languages from '../../data/languages';

const Navbar = () => {
  const { language, text, onChangeLanguage } = useContext(LanguageContext);

  const [navbarActive, setNavbarActive] = useState(false);
  const [activeSection, setActiveSection] = useState<number>(0);

  // scroll active animation
  useEffect(() => {
    let containerStartsAt: (number | null)[] = [];

    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Navbar styling after scroll
      setNavbarActive(scrollY > 100);

      // Active section
      const centerOfScreen =
        document.documentElement.scrollTop + window.innerHeight / 2;

      let active = 0;
      for (let i = containerStartsAt.length - 1; i > 0; i--) {
        const startsAt = containerStartsAt[i];

        if (startsAt === null) return;

        if (centerOfScreen > startsAt) {
          active = i;
          break;
        }
      }
      setActiveSection(active);
    };

    // Update `containerStartsAt` on every window resize, incase container heights change during window resize.
    const updateContainerStartsAt = () => {
      document.removeEventListener('scroll', handleScroll);

      containerStartsAt = [
        'container-მთავარი',
        'container-შესახებ',
        'container-კონტაქტი',
      ].map((containerId) => {
        const container = document.getElementById(containerId);

        if (!container) {
          console.error(`Container not found with an id '${containerId}'`);
          return null;
        }

        return container.offsetTop;
      });

      document.addEventListener('scroll', handleScroll);
    };

    updateContainerStartsAt();
    window.addEventListener('resize', updateContainerStartsAt);

    return () => {
      window.removeEventListener('resize', updateContainerStartsAt);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`${style.navbar} ${navbarActive ? style.active : ''}`}>
      <div className={style.inner}>
        <Logo />
        <nav className={style.navigation}>
          {text.navbar.sections.map((section, index) => (
            <a
              href={'#' + languages.ENG.navbar.sections[index]}
              className={activeSection === index ? style.active : ''}
            >
              {section}
            </a>
          ))}
        </nav>
        <NavResponsive />
        <MiniLogo />

        <div className={style.numberLanguageWrapper}>
          <button
            className={`${style.languageButton} ${navbarActive ? style.active : ''}`}
            onClick={() => onChangeLanguage(language === 'ENG' ? 'GEO' : 'ENG')}
          >
            {language === 'ENG' ? 'ENG' : 'GEO'}
          </button>

          <div className={style.number}>
            <h3>{text.navbar.name}</h3>
            <span>
              <FaPhone />
              <h3>599 47 80 16</h3>
            </span>
          </div>
        </div>

        <button
          className={`${style.languageButtonResponsive} ${
            navbarActive ? style.active : ''
          }`}
          onClick={() => onChangeLanguage(language === 'ENG' ? 'GEO' : 'ENG')}
        >
          {language === 'ENG' ? 'ENG' : 'GEO'}
        </button>
      </div>
    </header>
  );
};

export default Navbar;

'use client';
import { useState, useRef, useEffect, useContext } from 'react';
import style from './Navbar.module.scss';
import { HiMenu } from 'react-icons/hi';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import Logo from '../Logo/Logo';
import { LanguageContext } from '../../context/language.context';
import languages from '../../data/languages';

export default function NavResponsive() {
  // importing context
  const { text } = useContext(LanguageContext);

  const [show, setShow] = useState(false);

  const showMenu = () => {
    setShow(true);
  };

  const closeMenu = () => {
    setShow(false);
  };
  // hide navmenu after clicking link
  const handleLink = () => {
    setShow(false);
  };

  // outside click logic
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let handler = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  return (
    <>
      <div className={style.navLogo} onClick={showMenu}>
        <HiMenu />
      </div>

      <div
        className={`${style.responsiveMenu} ${show && style.responsiveMenuActive}`}
        ref={menuRef}
      >
        <nav>
          <div className={style.navLogoMenu} onClick={closeMenu}>
            <MdKeyboardArrowLeft />
          </div>
          {text.navbar.sections.map((section, index) => (
            <a
              onClick={handleLink}
              href={'#' + languages.ENG.navbar.sections[index]}
            >
              {section}
            </a>
          ))}
        </nav>
        <div className={style.logoHolder}>
          <Logo />
        </div>
      </div>
    </>
  );
}

import style from "./Logo.module.scss"

const FooterMiniLogo = () => {
  return (
    <a href='/' className={style.footerMiniLogo}>
      <h1>I</h1>
    </a>
  )
}

export default FooterMiniLogo
import { useContext, useEffect } from 'react';
import style from './Consult.module.scss';
import consultImage from '../../image/law.jpg';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { LanguageContext } from '../../context/language.context';

const Consult = () => {
  const { text } = useContext(LanguageContext);

  // animation logic
  useEffect(() => {
    const elements = document.querySelectorAll(
      `.${style.titleAnimation}, .${style.paragraphAnimation}, .${style.contactLink}`
    );
    const observer = new IntersectionObserver(
      (entries) => {
        console.log(entries); // Add this line for debugging
        entries.forEach((entry) => {
          if (entry.target.classList.contains(style.titleAnimation)) {
            // Apply styles or animations for text elements
            entry.target.classList.toggle(
              `${style.titleShow}`,
              entry.isIntersecting
            );
            if (entry.isIntersecting) observer.unobserve(entry.target);
          }
          if (entry.target.classList.contains(style.paragraphAnimation)) {
            // Apply styles or animations for text elements
            entry.target.classList.toggle(
              `${style.paragraphShow}`,
              entry.isIntersecting
            );
            if (entry.isIntersecting) observer.unobserve(entry.target);
          } else {
            // Apply styles or animations for other elements (cards)
            entry.target.classList.toggle(`${style.btnShow}`, entry.isIntersecting);
            if (entry.isIntersecting) observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 1,
        rootMargin: '0px 0px 68px 0px',
      }
    );

    elements.forEach((element) => observer.observe(element));
  }, []);

  return (
    <section
      id="container-მთავარი"
      className={style.consult}
      style={{ backgroundImage: `url(${consultImage})` }}
    >
      <div className={style.inner}>
        <h1 className={style.titleAnimation}>{text.consult.title}</h1>
        <p className={style.paragraphAnimation}>{text.consult.description}</p>
        <a href="#Contact" className={style.contactLink}>
          {text.consult.button} <FaLongArrowAltRight />
        </a>
      </div>
      <div className={style.overlay}></div>
    </section>
  );
};

export default Consult;

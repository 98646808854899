import style from "./Logo.module.scss"

const MiniLogo = () => {
  return (
    <a href='/' className={style.miniLogo}>
      <h1>I</h1>
    </a>
  )
}

export default MiniLogo
import { FaFacebookF, FaArrowUpRightFromSquare, FaWhatsapp } from 'react-icons/fa6';
import { FaTelegramPlane } from 'react-icons/fa';
import style from "./Socials.module.scss"

const Socials = () => {
  return (
    <div className={style.socials}>
      <a
        href="https://www.facebook.com/iuri.tsereteli"
        title="Facebook"
        target="_blank"
        rel="noreferrer"
        className={`${style.socialsLink} ${style.socialsLinkFacebook}`}
      >
        <div className={style.socialsLinkWrapper}>
          <FaFacebookF className={style.socialsLinkIcon} />
          <span className={style.socialsLinkText}>Facebook</span>
          <FaArrowUpRightFromSquare className={style.socialsLinkRedirectIcon} />
        </div>
      </a>
      <a
        href="https://wa.me/995599478016"
        title="Whatsapp"
        target="_blank"
        rel="noreferrer"
        className={`${style.socialsLink} ${style.socialsLinkWhatsapp}`}
      >
        <div className={style.socialsLinkWrapper}>
          <FaWhatsapp className={style.socialsLinkIcon} />
          <span className={style.socialsLinkText}>WhatsApp</span>
          <FaArrowUpRightFromSquare className={style.socialsLinkRedirectIcon} />
        </div>
      </a>
      <a
        href="https://t.me/+995599478016"
        title="Telegram"
        target="_blank"
        rel="noreferrer"
        className={`${style.socialsLink} ${style.socialsLinkTelegram}`}
      >
        <div className={style.socialsLinkWrapper}>
          <FaTelegramPlane className={style.socialsLinkIcon} />
          <span className={style.socialsLinkText}>Telegram</span>
          <FaArrowUpRightFromSquare className={style.socialsLinkRedirectIcon} />
        </div>
      </a>
    </div>
  )
}

export default Socials
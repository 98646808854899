import { createContext} from 'react';
import { useSearchParams } from 'react-router-dom';
import languages from '../data/languages';

type LanguageKeys = keyof typeof languages;

interface Context {
  language: LanguageKeys;
  text: (typeof languages)[LanguageKeys];
  onChangeLanguage: (language: LanguageKeys) => void;
}

const initial: Context = {
  language: 'GEO',
  text: languages['GEO'],
  onChangeLanguage: () => {},
};

export const LanguageContext = createContext(initial);

export function LanguageContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const language = (searchParams.get('lang') || 'GEO') as LanguageKeys;

  const text = languages[language];

  const onChangeLanguage: Context['onChangeLanguage'] = (language) => {
    console.log({ language });

    setSearchParams((params) => {
      params.set('lang', language);
      return params;
    });
  };

  return (
    <LanguageContext.Provider value={{ language, text, onChangeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

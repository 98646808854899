import style from './Logo.module.scss';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Logo = ({ className, ...restProps }: Props) => {
  return (
    <a href="/" className={`${style.logo} ${className || ''}`} {...restProps}>
      <h1>IURI.GE</h1>
    </a>
  );
};

export default Logo;

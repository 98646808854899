import style from './Footer.module.scss';
import FooterLogo from '../Logo/FooterLogo';
import FooterMiniLogo from '../Logo/FooterMiniLogo';
// icons
import { MdOutlineMail } from 'react-icons/md';
import { FaPhone } from 'react-icons/fa6';
import { FaFacebook, FaTelegram } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdKeyboardDoubleArrowUp } from 'react-icons/md';
import { useContext } from 'react';
import { LanguageContext } from '../../context/language.context';
import languages from '../../data/languages';

// importing images
import gio from "../../image/gio.gif"
import luka from "../../image/luka.gif"

const Footer = () => {
  const { text } = useContext(LanguageContext);

  return (
    <footer className={style.footer}>
      <a className={style.up} href="#Main">
        <MdKeyboardDoubleArrowUp />{' '}
      </a>
      <div className={style.inner}>
        <div className={style.navigationHolder}>
          <FooterLogo />
          <FooterMiniLogo />
          <nav className={style.nav}>
            {text.navbar.sections.map((section, index) => (
              <a href={'#' + languages.ENG.navbar.sections[index]}>{section}</a>
            ))}
          </nav>
        </div>
        <div className={style.infoHolder}>
          <h1 className={style.number}>
            <FaPhone /> (+995) 599 47 80 16
          </h1>
          <h1 className={style.mail}>
            <MdOutlineMail /> iuritsereteli12@gmail.com
          </h1>
        </div>
        <div className={style.contactHolder}>
          <a
            href="https://www.facebook.com/iuri.tsereteli"
            title="Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="https://wa.me/995599478016"
            title="Whatsapp"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoWhatsapp />
          </a>
          <a
            href="https://t.me/+995599478016"
            title="Telegram"
            target="_blank"
            rel="noreferrer"
          >
            <FaTelegram />
          </a>
        </div>
      </div>
      <p className={style.copyright}>
        © {text.footer.copyright} Developed by{' '}
        <a href="https://giorgimachitadze.vercel.app/" target="_blank" rel="noreferrer" className={style.gio}>
          <span style={{ backgroundImage: `url(${gio})` }}></span>
          Giorgi Machitadze
        </a> &{' '} 
        <a href="https://lukakobaidze.netlify.app/" target="_blank" rel="noreferrer" className={style.luka}>
          <span style={{ backgroundImage: `url(${luka})` }}></span>
          Luka Kobaidze
        </a>{' '}
      </p>
    </footer>
  );
};

export default Footer;

import { useState, useEffect, useRef, useContext } from 'react';
import style from './Contact.module.scss';
import emailjs from '@emailjs/browser';

// icons
import { MdOutlineMail } from 'react-icons/md';
import { FaPhone } from 'react-icons/fa6';
import { FaLongArrowAltRight } from 'react-icons/fa';
import ScrollId from '../ScrollId/ScrollId';
import { LanguageContext } from '../../context/language.context';
import languages from '../../data/languages';

const Contact = () => {
  const [inputValue, setInputValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [textValue, setTextValue] = useState('');

  // importing context
  const { text } = useContext(LanguageContext);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
  };

  // animation
  useEffect(() => {
    const animations = document.querySelectorAll(`.${style.animation}`);
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle(`${style.show}`, entry.isIntersecting);
          if (entry.isIntersecting) observer.unobserve(entry.target);
        });
      },
      {
        threshold: 1,
      }
    );
    animations.forEach((animations) => observer.observe(animations));
  }, []);

  // sending email logic
  const [isSubmitted, setIsSubmitted] = useState(false);

  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    if (!form.current) return;

    emailjs
      .sendForm(
        'service_wp0m6tn',
        'template_0utexv1',
        form.current,
        'cp6KabfiAAPIpVZeD'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset(); // Reset the form.
    setIsSubmitted(true);
  };

  return (
    <section id="container-კონტაქტი" className={style.contact}>
      <ScrollId id={languages.ENG.navbar.sections[2]} />
      <div className={style.inner}>
        <div className={style.personal}>
          <h1 className={style.animation}>{text.contact.call}</h1>
          <span className={`${style.number} ${style.animation}`}>
            <FaPhone /> (+995) 599 47 80 16
          </span>

          <div className={`${style.personalOr} ${style.animation}`}>
            <span></span>
            <p>{text.contact.or}</p>
          </div>

          <h1 className={style.animation}>{text.contact.mail}</h1>
          <span className={`${style.mail} ${style.animation}`}>
            <MdOutlineMail /> iuritsereteli12@gmail.com
          </span>
        </div>

        <div className={style.or}>
          <span></span>
          <p>{text.contact.or}</p>
        </div>

        <div className={style.emailContainer}>
          {!isSubmitted && (
            <h1 className={`${style.question} ${style.animation}`}>
              {text.contact.tell}
            </h1>
          )}
          {!isSubmitted && (
            <form ref={form} onSubmit={sendEmail}>
              <div className={`${style.nickname} ${style.animation}`}>
                <input
                  required
                  placeholder=" "
                  type="text"
                  id="displayName"
                  name="user_name"
                  onChange={handleNameChange}
                />
                <label
                  className={`${inputValue.length > 0 ? style.labelFilled : ''}`}
                >
                  {text.contact.nameTitle}
                </label>
              </div>

              <div className={`${style.email} ${style.animation}`}>
                <input
                  required
                  placeholder=" "
                  type="email"
                  id="signUpEmail"
                  name="user_email"
                  onChange={handleEmailChange}
                />
                <label
                  className={`${emailValue.length > 0 ? style.labelFilled : ''}`}
                >
                  {text.contact.mailTitle}
                </label>
              </div>

              <div className={`${style.textArea} ${style.animation}`}>
                <textarea
                  name="message"
                  placeholder=" "
                  id="text"
                  onChange={handleTextChange}
                  required
                />
                <label
                  className={`${textValue.length > 0 ? style.labelFilled : ''}`}
                >
                  {text.contact.messageTitle}
                </label>
                <span></span>
              </div>

              <button
                className={`${style.btn} ${style.animation}`}
                type="submit"
                value="Send"
              >
                {text.contact.button} <FaLongArrowAltRight />
              </button>
            </form>
          )}
          {isSubmitted && (
            <div className={style.messageContainer}>
              <h1>{text.contact.messageRequested1}</h1>
              <h1>{text.contact.messageRequested2}</h1>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
